jQuery(document).ready(function ($) {
  
  // faq toggle
  const faqToggle = (() => {
    $('a.faq_q').on('click', (e) => {
      e.preventDefault()

      const self = $(e.target)
      self.parent().siblings().find('p').slideUp(250)
      self.next('p').slideToggle(250)

      self.parents('.faq_item').siblings().find('span').removeClass('active')
      self.find('span').toggleClass('active')
    })  

    $('a.faq_q span').on('click', (e) => {       
      const self = $(e.target)

      self.parents('.faq_item').siblings().find('p').slideUp(250)
      self.parents('.faq_item').find('p').slideToggle(250)

      self.parents('.faq_item').siblings().find('span').removeClass('active')
      self.parents('.faq_item').find('span').toggleClass('active')
    })        
  })()

})