jQuery(document).ready(function ($) {

  if ($('.page-template-foundation').length) {
    // covid banner slider
    $('#atf_slider').lightSlider({
      item          : 1,
      loop          : true,
      controls      : true,
      pager         : false,
      adaptiveHeight: false,
      enableDrag    : false,
      nextHtml      : "<i class='ci ci_arrow_right'>&nbsp;</i>",
      mode          : 'fade'
    })
  }

})