jQuery(document).ready(function ($) {

  if ($('.page-template-careers').length) {
    // careers tab
    const careersTab = (() => {
      // label clicked
      $('.career_label').on('click', (e) => {
        const self      = $(e.target),
              dataIndex = self.attr('data-index')

        // toggle label classes
        self.siblings().removeClass('activeCareer')
        self.addClass('activeCareer')

        // toggle tabs
        $('.career_item').fadeOut(0)
        $(`.career_item[data-index=${dataIndex}]`).fadeIn(300)
      })
    })()
  }

})