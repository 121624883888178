jQuery(document).ready(function ($) {
  
  // I want to... redirects
  const wantToRedirects = (() => {
    // selection done
    $('#want_to_selection').on('change', (e) => {
      // init data
      const self           = $(e.target),
            url            = self.val(),
            homeUrl        = ajaxify.home_url

      // set redirects
      let urlRedirect = (url.search('external') !== -1) ? 
        window.open(url) : window.location.replace(url)
    })
  })()

  // Covid 19 notice - fade out
  const covid19Notice = (() => {
    $('.covid_notice').find('.ci_close').on('click', (e) => {
      $(e.target).parents('.covid_notice').fadeOut(300)
    })
  })()

  // main menu
  const mainMenu = (() => {
    const mainMenuItem = $('#main_menu_media #the_main_menu > li > a')

    // menu item clicked
    mainMenuItem.on('click', (e) => {
      const self          = $(e.target),
            selfParent    = self.parents('li'),
            activeSubmenu = self.next()

      if (!(selfParent.hasClass('menu-item-715') || selfParent.hasClass('menu-item-716'))) {
        e.preventDefault()
      }

      // class toggle for submenu
      // $('#the_main_menu .sub-menu').fadeOut(0)
      activeSubmenu.toggleClass('activeItem')
      self.parents('li').siblings().find('.sub-menu').removeClass('activeItem animateItems')

      // class toggle for menu item
      self.toggleClass('activeItem')
      self.parents('li').siblings().find('a').removeClass('activeItem')

      // fades
      // if (activeSubmenu.hasClass('activeItem')) {
      //   activeSubmenu.fadeIn(300, "swing", () => {
      //     activeSubmenu.addClass("animateItems")
      //   })
      // } else {
      //   activeSubmenu.fadeOut(0, "swing", () => {
      //     activeSubmenu.removeClass("animateItems")
      //   })
      // }
    })

    // also make sure to hide the menu & remove active classes if anything else clicked
    $(document).on('click', (e) => {
      if (!$(e.target).hasClass('activeItem')) {
        // $('#the_main_menu .sub-menu').fadeOut(0)
        $('.activeItem').removeClass('activeItem animateItems')
      }
    })
  })()

  // menu mobile
  const menuMobile = (() => {
    // init menu
    $('.sidr_left_link').sidr({
      name: 'sidr_left_menu',
      side: 'left'
    })

    // submenu output 
    $('#the_main_menu_m .menu-item-has-children > a').on('click', (e) => {
      const self = $(e.target)

      // add class for css to current submenu item
      self.parents('li').addClass('bg_none')

      // hide other items with submenu elements
      self.parents('li').siblings('li').fadeOut(0)
      $('#the_submenu_m').fadeOut(0)

      // show submenu
      self.next('.sub-menu').fadeIn(300)

      // set the 'back to main menu' button
      $('.menu_title_m').addClass('activeSubmenu').children('em').text('Back')    
    })

    // return to top lvl menu
    $('.menu_title_m em').on('click', (e) => {
      const self = $(e.target),
      backBtn    = self.parents('h2'),
      btnNxtDivs = backBtn.nextAll('div')
      
      if (backBtn.hasClass('activeSubmenu')) {
        btnNxtDivs.find('li').fadeIn(0).removeClass('bg_none')
        btnNxtDivs.find('.sub-menu').fadeOut(0) 

        $('#the_submenu_m').fadeIn(0)

        self.text('Main Menu')
        backBtn.removeClass('activeSubmenu')
      }
    })

    // 
    const searchInput = $('.search_form_wrap_m input')
    searchInput.on('click', (e) => {
      $(e.target).addClass('active')
    }).on('focusout', () => {
      searchInput.removeClass('active')
    })
  })()

  // translate - modify the text to 'Translate'
  const translate = (() => {
   let translateTimer = setInterval(() => {
     const firstOption = $('.goog-te-combo option:first-child')

     if (firstOption.length) {
       clearInterval(translateTimer)

       firstOption.text('Translate')
     }
   }, 0)
  })()

})