jQuery(document).ready(function ($) {
  
  if ($('.page-template-feedback').length) {
    // feedback fields reset
    const resetFeedbackFields = (() => {
      // reset fields on click
      $('.wpcf7-form p:last-of-type').find('button').on('click', () => {
        const selectField   = $('.wpcf7-form select'),
              commentField  = $('.wpcf7-form textarea'),
              yesRadioField = $('.wpcf7-form .your-permission .first input'),
               noRadioField = $('.wpcf7-form .your-permission .last input')

        $.each(selectField, function (i, val) { 
          selectField.val(selectField.find('option:first').val())
        })

        commentField.val('')

        // yesRadioField.attr('checked', true)
        // noRadioField.attr('checked', false)
        // yesRadioField.click()
        
      })
    })()
  }

})